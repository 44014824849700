exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-bar-page-index-tsx": () => import("./../../../src/templates/BarPage/index.tsx" /* webpackChunkName: "component---src-templates-bar-page-index-tsx" */),
  "component---src-templates-beach-club-page-index-tsx": () => import("./../../../src/templates/BeachClubPage/index.tsx" /* webpackChunkName: "component---src-templates-beach-club-page-index-tsx" */),
  "component---src-templates-beach-page-index-tsx": () => import("./../../../src/templates/BeachPage/index.tsx" /* webpackChunkName: "component---src-templates-beach-page-index-tsx" */),
  "component---src-templates-beauty-page-index-tsx": () => import("./../../../src/templates/BeautyPage/index.tsx" /* webpackChunkName: "component---src-templates-beauty-page-index-tsx" */),
  "component---src-templates-breakfast-page-index-tsx": () => import("./../../../src/templates/BreakfastPage/index.tsx" /* webpackChunkName: "component---src-templates-breakfast-page-index-tsx" */),
  "component---src-templates-club-page-index-tsx": () => import("./../../../src/templates/ClubPage/index.tsx" /* webpackChunkName: "component---src-templates-club-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-entertainment-page-index-tsx": () => import("./../../../src/templates/EntertainmentPage/index.tsx" /* webpackChunkName: "component---src-templates-entertainment-page-index-tsx" */),
  "component---src-templates-event-page-index-tsx": () => import("./../../../src/templates/EventPage/index.tsx" /* webpackChunkName: "component---src-templates-event-page-index-tsx" */),
  "component---src-templates-events-page-index-tsx": () => import("./../../../src/templates/EventsPage/index.tsx" /* webpackChunkName: "component---src-templates-events-page-index-tsx" */),
  "component---src-templates-fitness-page-index-tsx": () => import("./../../../src/templates/FitnessPage/index.tsx" /* webpackChunkName: "component---src-templates-fitness-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-info-page-index-tsx": () => import("./../../../src/templates/InfoPage/index.tsx" /* webpackChunkName: "component---src-templates-info-page-index-tsx" */),
  "component---src-templates-lake-page-index-tsx": () => import("./../../../src/templates/LakePage/index.tsx" /* webpackChunkName: "component---src-templates-lake-page-index-tsx" */),
  "component---src-templates-lamante-event-page-index-tsx": () => import("./../../../src/templates/LamanteEventPage/index.tsx" /* webpackChunkName: "component---src-templates-lamante-event-page-index-tsx" */),
  "component---src-templates-lamante-events-page-index-tsx": () => import("./../../../src/templates/LamanteEventsPage/index.tsx" /* webpackChunkName: "component---src-templates-lamante-events-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-meeting-page-index-tsx": () => import("./../../../src/templates/MeetingPage/index.tsx" /* webpackChunkName: "component---src-templates-meeting-page-index-tsx" */),
  "component---src-templates-offer-page-index-tsx": () => import("./../../../src/templates/OfferPage/index.tsx" /* webpackChunkName: "component---src-templates-offer-page-index-tsx" */),
  "component---src-templates-offers-page-index-tsx": () => import("./../../../src/templates/OffersPage/index.tsx" /* webpackChunkName: "component---src-templates-offers-page-index-tsx" */),
  "component---src-templates-party-page-index-tsx": () => import("./../../../src/templates/PartyPage/index.tsx" /* webpackChunkName: "component---src-templates-party-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-pool-page-index-tsx": () => import("./../../../src/templates/PoolPage/index.tsx" /* webpackChunkName: "component---src-templates-pool-page-index-tsx" */),
  "component---src-templates-quotation-page-index-tsx": () => import("./../../../src/templates/QuotationPage/index.tsx" /* webpackChunkName: "component---src-templates-quotation-page-index-tsx" */),
  "component---src-templates-rent-page-index-tsx": () => import("./../../../src/templates/RentPage/index.tsx" /* webpackChunkName: "component---src-templates-rent-page-index-tsx" */),
  "component---src-templates-resort-page-index-tsx": () => import("./../../../src/templates/ResortPage/index.tsx" /* webpackChunkName: "component---src-templates-resort-page-index-tsx" */),
  "component---src-templates-restaurant-page-index-tsx": () => import("./../../../src/templates/RestaurantPage/index.tsx" /* webpackChunkName: "component---src-templates-restaurant-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-spa-page-index-tsx": () => import("./../../../src/templates/SpaPage/index.tsx" /* webpackChunkName: "component---src-templates-spa-page-index-tsx" */),
  "component---src-templates-sport-page-index-tsx": () => import("./../../../src/templates/SportPage/index.tsx" /* webpackChunkName: "component---src-templates-sport-page-index-tsx" */),
  "component---src-templates-weddings-page-index-tsx": () => import("./../../../src/templates/WeddingsPage/index.tsx" /* webpackChunkName: "component---src-templates-weddings-page-index-tsx" */)
}

